import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useFetch from 'utils/useFetch';
import { FormatDate } from 'utils/dates';
import ErrorBoundary from 'utils/ErrorBoundary';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import Legende from 'components/Marche/Legende';

const Marche = () => {
    const { id } = useParams();
    const { data: marche, isLoading, error } = useFetch(`api/walks/${id}`);
    
    if (isLoading)
        return <Loading/>;

    if (error)
        return <ErrorAlert>Erreur au chargement de la marche</ErrorAlert>;

    return (<ErrorBoundary fallback={<ErrorAlert>Une erreur est survenue dans le chargement des données de la marche</ErrorAlert>}>
        <div className="container min-vh-100 p-5">
            <div className="row">
                <div className="col-xl-8 mb-4">
                    <h1 className="display-4">{marche.titreMarche} </h1>
                    <span className="lead">{marche.typeMarcheLibelle}  <Legendes id={id} /></span>

                    <h4 className="mt-2 text-primary"><i className="fas fa-calendar" /> {FormatDate(marche.date)}</h4>

                    <Link to={`/clubs/${marche.groupId}`} className="mt-4 text-primary"><i className="fas fa-users"/> <b>Groupe organisateur: </b>{marche.groupMatricule} - {marche.groupNom}</Link>
                    <Responsable groupId={marche?.groupId} />

                    <hr />

                    {/*<div className="mb-4">*/}
                    {/*    <h4 className="text-primary"><i className="fas fa-info-circle" /> Informations</h4>*/}
                    {/*    <div className="px-3 py-2">*/}
                    {/*        <p><b>Description:</b> {marche.description}</p>*/}
                    {/*        <p><b>Parcours Vélo:</b>  {(marche.velo ? <span className="text-primary"><i className="fas fa-bicycle" /> oui</span> : <span className="text-danger"><i className="fas fa-bicycle"/> non</span>)}</p>*/}
                    {/*        <p><b>Fête Mobile:</b>  {(marche.feteMobile ? <span className="text-primary"><i className="fas fa-arrows-turn-to-dots" /> oui</span> : <span className="text-danger"><i className="fas fa-arrows-turn-to-dots"/> non</span>)}</p>*/}
                    {/*        {marche.carteIgn && <p><b>Carte IGN:</b> {marche.carteIgn}</p>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {(marche.debutMarchePermanente || marche.finMarchePermanente) && <div className="mb-4 p-3">
                        <h4 className="text-primary">Marche Permanente</h4>
                        <p><b>Lieu Départ:</b> {marche.debutMarchePermanente}</p>
                        <p><b>Distance:</b> {marche.finMarchePermanente}</p>
                    </div>}
                    <div className="mb-4">
                        <h4 className="text-primary"><i className="fas fa-clipboard-list" /> Distances et Heures de départ</h4>
                        <Distances id={id}/>
                    </div>
                </div>
            
                <div className="col-xl-4">
                    <div className="card border-1 border-primary rounded-4 bg-secondary text-primary mb-4">
                        <div className="card-body p-5 ">
                            <div className="align-items-center">
                                <h2><i className="fas fa-map" /> Adresse</h2>
                                <p>{marche.lieuDepart}</p>
                                <p>{marche.adresseRue}</p>
                                <p>{marche.adresseCodePostal} - {marche.adresseVille}</p>
                                <p>{marche.adressePays}</p>
                                {(marche.urlMaps === null) &&
                                    <a target="_blank"
                                        rel="noreferrer"
                                        href={`http://maps.google.com/maps?daddr=${marche.adresseRue}+${marche.adresseVille}`}>Ouvrir dans Google Maps</a>
                                }
                                {(marche.urlMaps !== null) &&
                                    <a target="_blank"
                                        rel="noreferrer"
                                        href={`${marche.urlMaps}`}>Ouvrir dans Google Maps</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ErrorBoundary>);
}

const Distances = ({ id }) => {
    const { data, isLoading, error } = useFetch(`api/walks/${id}/distances`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Erreur lors du chargement des distances</ErrorAlert>;

    return data?.map((e) => (<p className="mb-0 small" key={e.id}>
        {e?.distance && `${e.distance} Km | `}{e?.heureDepart && e.heureDepart}
    </p>))
}

const Legendes = ({ id }) => {
    const { data, isLoading, error } = useFetch(`api/walks/${id}/keys`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Erreur lors du chargement de la légende</ErrorAlert>;

    return data?.map(e => (<Legende key={e.id} >
        {e.icon && <i className={(`fas ${e.icon}`)} />} {e?.libelle}
    </Legende>));
}

const Responsable = ({ groupId }) => {
    const { data, isLoading, error } = useFetch(`api/groups/${groupId}/roles/calendar`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Erreur lors du chargement du responsable de club</ErrorAlert>;

    const { nom, prenom, gsm, telephonne, mail } = data;

    return (<div>
        <p className="text-primary"><i className="fas fa-user mt-3" /> <b>Responsable calendrier du club: </b></p>
        <div className="px-3 py-2">
            <p>{nom} {prenom}</p>
            {mail && <p><i className="fas fa-envelope" /> {mail.split('*')[0]}</p>}
            {gsm && <p><i className="fas fa-mobile" /> {gsm}</p>}
            {telephonne && <p><i className="fas fa-phone" /> {telephonne}</p>}
        </div>
    </div>);
}

export default Marche;